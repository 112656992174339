import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation as useAdminMutation, useQueryClient } from '@tanstack/react-query';
import type { MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type {
  BuyerBusinessCreatePayload,
  BuyerBusinessEntity,
  BuyerBusinessUpdatePGCToken,
  BuyerBusinessUpdatePayload,
  LinkUserToBusinessPayload,
} from '@wolfejs/ms-api-js/src/types/buyers';
import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to create a buyer
 */
export const useAdminBuyerCreate = (
  options?: UseMutationOptions<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_create';

  return useAdminMutation<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessCreatePayload>(
    (payload: BuyerBusinessCreatePayload) => client.admin.buyer.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a buyer
 */
export const useAdminBuyerUpdate = (
  options?: UseMutationOptions<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_update';

  return useAdminMutation<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>(
    (payload: BuyerBusinessUpdatePayload) => client.admin.buyer.update(payload.id, payload.business),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a buyer
 */
export const useAdminBuyerUpdateByBusinessId = (
  options?: UseMutationOptions<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_update_by_business_id';

  return useAdminMutation<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>(
    (payload: BuyerBusinessUpdatePayload) => client.admin.buyer.updateByBusinessId(payload.id, payload.business),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete a buyer
 */
export const useAdminBuyerDelete = (options?: UseMutationOptions<MsApiResponse<boolean>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_delete';

  return useAdminMutation<MsApiResponse<boolean>, Error, string>(
    (buyerId: string) => client.admin.buyer.delete(buyerId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a buyer for logged in user
 */
// export const useAdminBuyerUpdateForUser = (
//   options?: UseMutationOptions<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>
// ) => {
//   const { client } = useMsClient();
//   const queryClient = useQueryClient();

//   const key = 'buyer_update';

//   return useAdminMutation<MsApiResponse<BuyerBusinessEntity>, Error, BuyerBusinessUpdatePayload>(
//     (payload: BuyerBusinessUpdatePayload) => client.admin.buyer.update(payload.id, payload),
//     buildOptions(queryClient, [key, 'detail'], options)
//   );
// };

/**
 * This hook is used to update a buyer
 */
export const useAdminBuyerUpdatePGCToken = (
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, BuyerBusinessUpdatePGCToken>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_update_pgctoken';

  return useAdminMutation<MsApiResponse<boolean>, Error, BuyerBusinessUpdatePGCToken>(
    (payload: BuyerBusinessUpdatePGCToken) => client.admin.buyer.updatePGCToken(payload.id, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * Add user to buyer business
 */
export const useLinkUserToBusiness = (
  options?: UseMutationOptions<MsApiResponse<unknown>, Error, LinkUserToBusinessPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'buyer_link_user';

  return useAdminMutation<MsApiResponse<unknown>, Error, LinkUserToBusinessPayload>(
    (payload: LinkUserToBusinessPayload) => client.admin.buyer.linkUserToBusiness(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
