import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FilePayload, MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type {
  UserCreatePayload,
  UserEntity,
  UserUpdatePasswordPayload,
  UserUpdatePayload,
} from '@wolfejs/ms-api-js/src/types/users';
import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to upload image for user profile
 */
export const useAdminUserProfileImageUpload = (
  userId: string,
  options?: UseMutationOptions<string, Error, FilePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_profile_image_upload';

  return useMutation<string, Error, FilePayload>(
    payload => client.admin.users.uploadUserProfileImage(userId, payload),
    buildOptions(queryClient, [key, 'profile-image'], options)
  );
};

/**
 * This hook is used to delete uploaded profile image for a user
 */
export const useAdminUserProfileImageDelete = (
  userId: string,
  options?: UseMutationOptions<MsApiResponse<boolean>, Error>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'profile_image_delete';

  return useMutation<MsApiResponse<boolean>, Error>(
    () => client.admin.users.deleteProfileImage(userId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a user details
 */
export const useAdminUserUpdate = (
  id: string,
  options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, UserUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_update';

  return useMutation<MsApiResponse<UserEntity>, Error, UserUpdatePayload>(
    (payload: UserUpdatePayload) => client.admin.users.update(id, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a user details
 */
export const useAdminUserUpdate2 = (
  options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, UserUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_update';

  return useMutation<MsApiResponse<UserEntity>, Error, UserUpdatePayload>(
    (payload: UserUpdatePayload) => client.admin.users.update(payload.id || '-', payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to create a user
 */
export const useAdminUserCreate = (
  options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, UserCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_create';

  return useMutation<MsApiResponse<UserEntity>, Error, UserCreatePayload>(
    (payload: UserCreatePayload) => client.admin.users.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update signed-in user's password
 */
export const useAdminUserUpdatePassword = (
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, UserUpdatePasswordPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_pwd_update';

  return useMutation<MsApiResponse<boolean>, Error, UserUpdatePasswordPayload>(
    (e: UserUpdatePasswordPayload) => client.admin.users.updatePassword(e),
    buildOptions(queryClient, [key], options)
  );
};

/**
 * This hook is used to archive a user
 */
export const useAdminUserDelete = (options?: UseMutationOptions<MsApiResponse<null>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_delete';

  return useMutation<MsApiResponse<null>, Error, string>(
    (id: string) => client.admin.users.delete(id),
    buildOptions(queryClient, [key], options)
  );
};

/**
 * This hook is used to archive a user
 */
export const useAdminUserRestore = (options?: UseMutationOptions<MsApiResponse<null>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_restore';

  return useMutation<MsApiResponse<null>, Error, string>(
    (id: string) => client.admin.users.restore(id),
    buildOptions(queryClient, [key], options)
  );
};

/**
 * This hook is used to archive a user
 */
export const useAdminUserHardDelete = (options?: UseMutationOptions<MsApiResponse<null>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'user_harddelete';

  return useMutation<MsApiResponse<null>, Error, string>(
    (id: string) => client.admin.users.delete(id),
    buildOptions(queryClient, [key], options)
  );
};
