import { useQuery } from '@tanstack/react-query';
import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import { useMsClient } from '../../contexts';

/**
 * This hook is used to get the currently logged in user's details. Can also be used to check if there is an authenticated user.
 */
export function useUserSession(id: string) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.users.get(id);
  return useQuery({
    queryKey: [key],
    queryFn: async () => (await client.admin.users.get(id)).data,
  });
}
