'use client';

// import type { QueryClientProviderProps } from '@tanstack/react-query';
import MsJSClient from '@wolfejs/ms-api-js/src';
import React from 'react';

export interface MsClientContextState {
  /**
   * The MsClient JS Client instance.
   */
  client: MsJSClient;
}

const MsClientContext = React.createContext<MsClientContextState | null>(null);

/**
 * This hook gives you access to context of {@link MsClientProvider}.
 */
export const useMsClient = () => {
  const context = React.useContext(MsClientContext);
  if (!context) {
    throw new Error('useMsClient must be used within a MsClientProvider');
  }
  return context;
};

export type MsClientProviderProps = {
  /**
   * The URL to your MsClient backend.
   */
  baseUrl: string;

  /**
   * An object used to set the Tanstack Query client. The object requires a `client` property,
   * which should be an instance of [QueryClient](https://tanstack.com/query/v4/docs/react/reference/QueryClient).
   */
  // queryClientProviderProps?: QueryClientProviderProps;

  /**
   * @ignore
   */
  children: React.ReactNode;

  /**
   * Number of times to retry a request if it fails.
   *
   * @defaultValue 3
   */
  maxRetries?: number;

  projectPrefix?: string;
  systemPrefix?: string;
  options?: Record<string, unknown>;
};

/**
 * The `MsClientProvider` must be used at the highest possible point in the React component tree. Using any of `medusa-react`'s hooks or providers requires having `MsClientProvider`
 * higher in the component tree.
 */
export const MsClientProvider = ({
  baseUrl,
  projectPrefix,
  systemPrefix,
  children,
  options,
}: MsClientProviderProps) => {
  const httpClient = new MsJSClient(
    {
      baseURL: baseUrl,
    },
    {
      systemPrefix,
      projectPrefix,
      ...options,
    }
  );
  return (
    // <QueryClientProvider >
    <MsClientContext.Provider
      value={{
        client: httpClient,
      }}
    >
      {children}
    </MsClientContext.Provider>
    // </QueryClientProvider>
  );
};
