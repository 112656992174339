import { useQuery } from '@tanstack/react-query';
import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { PagedDataSource, SearchParams } from '@wolfejs/ms-api-js/src/types/api';
import type { BuyerBusinessEntity, BuyerBusinessSettings } from '@wolfejs/ms-api-js/src/types/buyers';
import type { MerchantBusinessEntity } from '@wolfejs/ms-api-js/src/types/merchants';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { createPathWithSearchParams } from '@wolfejs/ms-api-react/src/utils/createPathWithSearchParams';
import { useMsClient } from '../../../contexts';

/**
 * This hook is used to get all merchants
 */
export function useAdminMerchants(
  searchParams?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<MerchantBusinessEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.merchants.list(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.list(searchParams)).data,
  });
}

/**
 * This hook is used to get merchant by id
 */
export function useAdminMerchant(id: string, options?: ReactQueryOptions<MerchantBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.get(id)).data,
  });
}

/**
 * This hook is used to get merchant by id
 */
export function useAdminMerchantByBusinessId(id: string, options?: ReactQueryOptions<MerchantBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.getByBusinessId(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.getByBusinessId(id)).data,
  });
}

/**
 * This hook is used to merchants's business profile by merchant id
 */
export function useAdminMerchantProfile(merchantId: string, options?: ReactQueryOptions<MerchantBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.profile(merchantId);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.getMerchantProfile(merchantId)).data,
  });
}

/**
 * This hook is used to check if merchant exists by email, returns true if merchant exists.
 */
export function useAdminMerchantExists(email: string, options?: ReactQueryOptions<MerchantBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.existsByEmail(email);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.existsByEmail(email)).data,
  });
}

/**
 * This hook is used to get merchant by slug
 */
export function useAdminMerchantBySlug(slug: string, options?: ReactQueryOptions<MerchantBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.getBySlug(slug);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.getBySlug(slug)).data,
  });
}

/**
 * List all buyers for a merchant
 */
export function useAdminMerchantBuyers(
  id: string,
  search?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<BuyerBusinessEntity>>
) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.listBuyers(id, search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.listBuyers(id, search)).data,
  });
}

/**
 * Retrieve merchant buyer settings
 */
export function useAdminMerchantBuyerSettings(
  merchantId: string,
  businessId: string,
  options?: ReactQueryOptions<BuyerBusinessSettings>
) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.buyerSettings(merchantId, businessId);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.getBuyerSettings(merchantId, businessId)).data,
  });
}
