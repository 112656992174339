import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { FilePayload, MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type { PortalCreatePayload, PortalEntity, PortalUpdatePayload } from '@wolfejs/ms-api-js/src/types/portals';
import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to create a portal
 */
export const useAdminPortalCreate = (
  options?: UseMutationOptions<MsApiResponse<PortalEntity>, Error, PortalCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'portal_create';

  return useMutation<MsApiResponse<PortalEntity>, Error, PortalCreatePayload>(
    (payload: PortalCreatePayload) => client.admin.portal.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a portal
 */
export const useAdminPortalUpdate = (
  options?: UseMutationOptions<MsApiResponse<PortalEntity>, Error, PortalUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'portal_update';

  return useMutation<MsApiResponse<PortalEntity>, Error, PortalUpdatePayload>(
    (payload: PortalUpdatePayload) => client.admin.portal.update(payload.id!, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete a portal
 */
export const useAdminPortalDelete = (options?: UseMutationOptions<MsApiResponse<boolean>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'portal_delete';

  return useMutation<MsApiResponse<boolean>, Error, string>(
    (payload: string) => client.admin.portal.delete(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to upload image for entity id
 */
export const useAdminPortalLogoUpload = (
  portalId: string,
  options?: UseMutationOptions<boolean, Error, FilePayload | FilePayload[]>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'logo_upload';

  return useMutation<boolean, Error, FilePayload | FilePayload[]>(
    payload =>
      Array.isArray(payload)
        ? client.admin.portal.uploadPortalLogos(portalId, payload)
        : client.admin.portal.uploadPortalLogo(portalId, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to upload image for entity id
 */
export const useAdminPortalLogoDelete = (portalId: string, options?: UseMutationOptions<boolean, Error, string[]>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'logo_delete';
  return useMutation<boolean, Error, string[]>(
    logoIds => {
      return client.admin.portal.deletePortalLogos(portalId, logoIds);
    },
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
/**
 * This hook is used to save portal settings
 */
export const useAdminPortalThemeSetting = <T>(
  portalId: string,
  options?: UseMutationOptions<MsApiResponse<T>, Error, T>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'portal_settings';

  return useMutation<MsApiResponse<T>, Error, T>(
    (payload: T) => client.admin.portal.saveThemeSettings(portalId, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
