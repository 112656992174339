import { useQuery } from '@tanstack/react-query';
import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { PagedDataSource, SearchParams } from '@wolfejs/ms-api-js/src/types/api';
import type { PortalEntity } from '@wolfejs/ms-api-js/src/types/portals';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { createPathWithSearchParams } from '@wolfejs/ms-api-react/src/utils/createPathWithSearchParams';
import { useMsClient } from '../../..';

/**
 * This hook is used to get all portals
 */
export function useAdminPortals(
  searchParams?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<PortalEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.portals.list(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.portal.list(searchParams)).data,
  });
}

/**
 * This hook is used to get portal by id
 */
export function useAdminGetPortalById(id: string, options?: ReactQueryOptions<PortalEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.portals.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.portal.get(id)).data,
  });
}

/**
 * This hook is used to get all portals for a brand
 */
export function useAdminPortalsByBrandId(
  brandId: string,
  searchParams?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<PortalEntity>>
) {
  const { client } = useMsClient();

  const key = createPathWithSearchParams(MsAdminApiEndPoints.portals.getByBrand(brandId), searchParams);

  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.portal.getAllByBrandId(brandId, searchParams)).data,
  });
}

/**
 * This hook is used to get portal settings
 */
export const useAdminGetPortalThemeSetting = <T>(portalId: string, options?: ReactQueryOptions<T>) => {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.portals.themeSettings(portalId);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.portal.getThemeSettings<T>(portalId)).data,
  });
};
