'use client';

import React from 'react';

export interface SmartyStreetsContextState {
  /**
   * The Smarty embedded key
   */
  embeddedKey: string;
}

const SmartyStreetsContext = React.createContext<SmartyStreetsContextState | null>(null);

/**
 * This hook gives you access to context of {@link SmartyStreetsProvider}.
 */
export const useSmartyStreetsContext = () => {
  const context = React.useContext(SmartyStreetsContext);
  if (!context) {
    throw new Error('useSmartyStreetsContext must be used within a SmartyStreetsProvider');
  }

  return context;
};

export type SmartyStreetsProviderProps = {
  /**
   * The embedded key for your app's provisioned Smarty account
   */
  embeddedKey: string;
  children: React.ReactNode;
};

/**
 * The `SmartyStreetsProvider` should be used as low as possible in your app to mitigate potential future performance problems
 */
export const SmartyStreetsProvider = ({ embeddedKey, children }: SmartyStreetsProviderProps) => {
  return (
    <SmartyStreetsContext.Provider
      value={{
        embeddedKey,
      }}
    >
      {children}
    </SmartyStreetsContext.Provider>
  );
};
