import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { HeaderKeys } from '@wolfejs/ms-api-js/src/constants/header-keys';
import type { MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type {
  BuyerBusinessSignupPayload,
  BuyerUserSignupPayload,
  ForgotPasswordEntity,
  UpdateUserPasswordPayload,
  UserEmailTokenVerificationPayload,
  UserForgotPasswordPayload,
  UserResetPasswordPayload,
  UserSendSignupConfirmationPayload,
  UserSigninPayload,
  UserSignoutPayload,
  UserSignupPayload,
} from '@wolfejs/ms-api-js/src/types/auth';
import type { BuyerBusinessSignupResponse } from '@wolfejs/ms-api-js/src/types/buyers';
import type { UserApiKeyEntity, UserEntity } from '@wolfejs/ms-api-js/src/types/users';
import { useMsClient } from '../..';
import { buildOptions } from '../../utils/buildOptions';

export const useSignin = (options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, UserSigninPayload>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'signin';

  return useMutation<MsApiResponse<UserEntity>, Error, UserSigninPayload>(
    (payload: UserSigninPayload) => {
      const appIdHeaders: Record<string, string | undefined> = {};
      appIdHeaders[HeaderKeys.APP_ID] = payload?.appId;
      appIdHeaders[HeaderKeys.APP_SUB_ID] = payload?.appSubId;

      return client.auth.signin(payload, { headers: appIdHeaders });
    },
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useSignout = (options?: UseMutationOptions<MsApiResponse<void>, Error, UserSignoutPayload>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'signout';

  return useMutation<MsApiResponse<void>, Error, UserSignoutPayload>(
    (payload: UserSignoutPayload) => {
      const appIdHeaders: Record<string, string | undefined> = {};
      appIdHeaders[HeaderKeys.APP_ID] = payload?.appId;
      appIdHeaders[HeaderKeys.APP_SUB_ID] = payload?.appSubId;

      return client.auth.signout({ headers: appIdHeaders });
    },
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useEmailTokenVerification = (
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, UserEmailTokenVerificationPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'email_token_verification';

  return useMutation<MsApiResponse<boolean>, Error, UserEmailTokenVerificationPayload>(
    (payload: UserEmailTokenVerificationPayload) => client.auth.emailTokenVerification(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useSendSignupConfirmation = (
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, UserSendSignupConfirmationPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'send_signup_confirmation';

  return useMutation<MsApiResponse<boolean>, Error, UserSendSignupConfirmationPayload>(
    (payload: UserSendSignupConfirmationPayload) => client.auth.sendSignupConfirmation(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useSignup = (options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, UserSignupPayload>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'signup';

  return useMutation<MsApiResponse<UserEntity>, Error, UserSignupPayload>(
    (payload: UserSignupPayload) => client.auth.signup(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const usePasswordReset = (
  options?: UseMutationOptions<MsApiResponse<void>, Error, UserResetPasswordPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'password_reset';

  return useMutation<MsApiResponse<void>, Error, UserResetPasswordPayload>(
    (payload: UserResetPasswordPayload) => client.auth.resetPassword(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useForgotPassword = (
  options?: UseMutationOptions<MsApiResponse<ForgotPasswordEntity>, Error, UserForgotPasswordPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'forgot_passwqord';

  return useMutation<MsApiResponse<ForgotPasswordEntity>, Error, UserForgotPasswordPayload>(
    (payload: UserForgotPasswordPayload) => client.auth.forgotPassword(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

// ----- Buyers signup

export const useSignupBuyerBusiness = (
  options?: UseMutationOptions<MsApiResponse<BuyerBusinessSignupResponse>, Error, BuyerBusinessSignupPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'signup_buyer_business';

  return useMutation<MsApiResponse<BuyerBusinessSignupResponse>, Error, BuyerBusinessSignupPayload>(
    (payload: BuyerBusinessSignupPayload) => client.auth.signupBuyerBusiness(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useSignupBuyer = (
  options?: UseMutationOptions<MsApiResponse<UserEntity>, Error, BuyerUserSignupPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'signup_buyer';

  return useMutation<MsApiResponse<UserEntity>, Error, BuyerUserSignupPayload>(
    (payload: BuyerUserSignupPayload) => client.auth.signupBuyerUser(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

export const useUpdatePassword = (
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, UpdateUserPasswordPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'update_password';

  return useMutation<MsApiResponse<boolean>, Error, UpdateUserPasswordPayload>(
    (payload: UpdateUserPasswordPayload) => client.auth.updatePassword(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to retrieve user's api-key
 */
export const useRetrieveApiKey = (options?: UseMutationOptions<MsApiResponse<UserApiKeyEntity>, Error>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'retrieve_api_key';

  return useMutation<MsApiResponse<UserApiKeyEntity>, Error>(
    () => client.auth.retrieveApiKey(),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to generate user's api-key
 */
export const useGenerateApiKey = (options?: UseMutationOptions<MsApiResponse<UserApiKeyEntity>, Error, boolean>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'generate_api_key';

  return useMutation<MsApiResponse<UserApiKeyEntity>, Error, boolean>(
    (hasApiKey: boolean) => (hasApiKey ? client.auth.regenerateApiKey() : client.auth.generateApiKey()),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to revoke user's api-key
 */
export const useRevokeApiKey = (options?: UseMutationOptions<MsApiResponse<void>, Error>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'revoke_api_key';

  return useMutation<MsApiResponse<void>, Error>(
    () => client.auth.revokeApiKey(),
    buildOptions(queryClient, [key], options)
  );
};
