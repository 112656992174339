import { useQuery } from '@tanstack/react-query';

import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src/api/admin';
import type { MerchantRepresentativeEntity, PagedDataSource, SearchParams } from '@wolfejs/ms-api-js/src/types/api';
import type { BuyerBusinessEntity } from '@wolfejs/ms-api-js/src/types/buyers';
import type { UserEntity } from '@wolfejs/ms-api-js/src/types/users';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { createPathWithSearchParams } from '@wolfejs/ms-api-react/src/utils/createPathWithSearchParams';
import { useMsClient } from '../../../contexts';

/**
 * List all buyers
 */
export function useAdminBuyers(
  search?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<BuyerBusinessEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.buyers.list(), search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.list(search)).data,
  });
}

/**
 * Get a buyer by id
 */
export function useAdminBuyer(id: string, options?: ReactQueryOptions<BuyerBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.buyers.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.get(id)).data,
  });
}

/**
 * Get a buyer by id
 */
export function useAdminBuyerAccountManager(options?: ReactQueryOptions<MerchantRepresentativeEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.buyers.getAccountManager();
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.getAccountManager()).data,
  });
}

export function useBuyerAccountManager(options?: ReactQueryOptions<MerchantRepresentativeEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.buyers.getAccountManager();
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.getAccountManager()).data,
  });
}

/**
 * List all users for a buyer
 */
export function useAdminBuyerUsers(
  id: string,
  search?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<BuyerBusinessEntity>>
) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.buyers.listUsers(id, search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.listUsers(id, search)).data,
  });
}

/**
 * List all users for a merchant
 */
export function useAdminMerchantUsers(
  id: string,
  search?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<UserEntity>>
) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.merchants.listUsers(id, search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.merchant.listUsers(id, search)).data,
  });
}
