import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation as useAdminMutation, useMutation, useQueryClient } from '@tanstack/react-query';
import type { FilePayload, MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type { BrandCreatePayload, BrandEntity, BrandUpdatePayload } from '@wolfejs/ms-api-js/src/types/brands';

import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to create a brand
 */
export const useAdminBrandCreate = (
  options?: UseMutationOptions<MsApiResponse<BrandEntity>, Error, BrandCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'brand_create';

  return useAdminMutation<MsApiResponse<BrandEntity>, Error, BrandCreatePayload>(
    (payload: BrandCreatePayload) => client.admin.brand.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a brand
 */
export const useAdminBrandUpdate = (
  options?: UseMutationOptions<MsApiResponse<BrandEntity>, Error, BrandUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'brand_update';

  return useAdminMutation<MsApiResponse<BrandEntity>, Error, BrandUpdatePayload>(
    (payload: BrandUpdatePayload) => client.admin.brand.update(payload.id!, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete a brand
 */
export const useAdminBrandDelete = (options?: UseMutationOptions<MsApiResponse<boolean>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'brand_delete';

  return useAdminMutation<MsApiResponse<boolean>, Error, string>(
    (brandId: string) => client.admin.brand.delete(brandId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to upload logo for brand
 */
export const useAdminBrandLogoUpload = (brandId: string, options?: UseMutationOptions<string, Error, FilePayload>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'brand_logo_upload';

  return useMutation<string, Error, FilePayload>(
    payload => client.admin.brand.uploadLogo(brandId, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete uploaded logo
 */
export const useAdminBrandLogoDelete = (
  brandId: string,
  options?: UseMutationOptions<MsApiResponse<boolean>, Error>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'brand_logo_delete';

  return useMutation<MsApiResponse<boolean>, Error>(
    () => client.admin.brand.deleteLogo(brandId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
