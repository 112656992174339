import { useQuery } from '@tanstack/react-query';

import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src/api/admin';
import type { PagedDataSource, SearchParams } from '@wolfejs/ms-api-js/src/types/api';
import type { BrandEntity } from '@wolfejs/ms-api-js/src/types/brands';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { createPathWithSearchParams } from '@wolfejs/ms-api-react/src/utils/createPathWithSearchParams';
import { useMsClient } from '../../..';

/**
 * This hook is used to get all brands
 */
export function useAdminBrands(search?: SearchParams, options?: ReactQueryOptions<PagedDataSource<BrandEntity>>) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.brands.list(), search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.brand.list(search)).data,
  });
}

/**
 * This hook is used to get brand by id
 */
export function useAdminBrand(id: string, options?: ReactQueryOptions<BrandEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.brands.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.brand.get(id)).data,
  });
}

/**
 * This hook is used to get all brands for a merchant
 */
export function useAdminBrandsByMerchantId(
  merchantId: string,
  searchParams?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<BrandEntity>>
) {
  const { client } = useMsClient();

  const key = createPathWithSearchParams(MsAdminApiEndPoints.brands.getByMerchant(merchantId), searchParams);

  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.brand.getAllByMerchantId(merchantId, searchParams)).data,
  });
}

/**
 * This hook is used to get brand by slug
 */
export function useAdminBrandBySlug(slug: string, options?: ReactQueryOptions<BrandEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.brands.getBySlug(slug);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.brand.getBySlug(slug)).data,
  });
}
