import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { FilePayload, MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type { BuyerBusinessSettings } from '@wolfejs/ms-api-js/src/types/buyers';
import type {
  MerchantBusinessCreatePayload,
  MerchantBusinessEntity,
  MerchantBusinessUpdatePayload,
} from '@wolfejs/ms-api-js/src/types/merchants';
import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to create a merchant
 */
export const useAdminMerchantCreate = (
  options?: UseMutationOptions<MsApiResponse<MerchantBusinessEntity>, Error, MerchantBusinessCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_create';

  return useMutation<MsApiResponse<MerchantBusinessEntity>, Error, MerchantBusinessCreatePayload>(
    (payload: MerchantBusinessCreatePayload) => client.admin.merchant.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a merchant
 */
export const useAdminMerchantUpdate = (
  options?: UseMutationOptions<MsApiResponse<MerchantBusinessEntity>, Error, MerchantBusinessUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_update';

  return useMutation<MsApiResponse<MerchantBusinessEntity>, Error, MerchantBusinessUpdatePayload>(
    (payload: MerchantBusinessUpdatePayload) => client.admin.merchant.update(payload.id!, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete a merchant
 */
export const useAdminMerchantDelete = (options?: UseMutationOptions<MsApiResponse<boolean>, Error, String>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_delete';

  return useMutation<MsApiResponse<boolean>, Error, string>(
    (merchantId: string) => client.admin.merchant.delete(merchantId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete uploaded logo
 */
export const useAdminMerchantLogoDelete = (
  merchantId: string,
  options?: UseMutationOptions<MsApiResponse<boolean>, Error>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_logo_delete';

  return useMutation<MsApiResponse<boolean>, Error>(
    () => client.admin.merchant.deleteLogo(merchantId),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to upload logo for merchant
 */
export const useAdminMerchantLogoUpload = (
  merchantId: string,
  options?: UseMutationOptions<string, Error, FilePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_logo_upload';

  return useMutation<string, Error, FilePayload>(
    payload => client.admin.merchant.uploadLogo(merchantId, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update a merchant's buyer settings
 */
export const useAdminMerchantUpdateBuyerSettings = (
  merchantId: string,
  businessId: string,
  options?: UseMutationOptions<MsApiResponse<boolean>, Error, Partial<BuyerBusinessSettings>>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'merchant_update_buyer_settings';

  return useMutation<MsApiResponse<boolean>, Error, Partial<BuyerBusinessSettings>>(
    payload => client.admin.merchant.updateBuyerSettings(merchantId, businessId, payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
