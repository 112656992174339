import { useQuery } from '@tanstack/react-query';
import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { PortalEntity } from '@wolfejs/ms-api-js/src/types/portals';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { useMsClient } from '../../..';

/**
 * This hook is used to get portal by slug
 */
export function usePortalBySlug(slug: string, options?: ReactQueryOptions<PortalEntity>) {
  const { client } = useMsClient();

  const key = MsApiEndPoints.portals.bySlug(slug);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.portal.getBySlug(slug)).data,
  });
}

/**
 * This hook is used to get portal by id or slug
 */
export function usePortalByIdOrSlug(idOrSlug: string, options?: ReactQueryOptions<PortalEntity>) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.portals.bySlug(idOrSlug);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.portal.getByIdOrSlug(idOrSlug)).data,
  });
}

/**
 * This hook is used to get portal settings
 */
export const useGetPortalThemeSetting = <T>(portalId: string, options?: ReactQueryOptions<T>) => {
  const { client } = useMsClient();

  const key = MsApiEndPoints.portals.themeSetings(portalId);

  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.portal.getThemeSettings<T>(portalId)).data,
  });
};
