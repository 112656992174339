import { useQuery } from '@tanstack/react-query';
import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src/api/admin/endPoints';
import type { PagedDataSource } from '@wolfejs/ms-api-js/src/types/api';
import type { UserEntity } from '@wolfejs/ms-api-js/src/types/users';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import type { SearchParams } from '@wolfejs/ui/Table/types';
import { useMsClient } from '../../../contexts';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';

export function useAdminUsers(searchParams?: SearchParams, options?: ReactQueryOptions<PagedDataSource<UserEntity>>) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.users.list(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.users.list(searchParams)).data,
  });
}

export function useAdminUser(id: string, options?: ReactQueryOptions<UserEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.users.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.users.get(id)).data,
  });
}

export function useAdminUserBy(search: Partial<UserEntity>, options?: ReactQueryOptions<UserEntity>) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.users.list(), search);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.users.getBy(search)).data,
  });
}
