import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MsApiResponse } from '@wolfejs/ms-api-js/src/types/api';
import type {
  EmailTemplateCreatePayload,
  EmailTemplateEntity,
  EmailTemplateUpdatePayload,
} from '@wolfejs/ms-api-js/src/types/emailTemplate';
import { useMsClient } from '../../..';
import { buildOptions } from '../../../utils/buildOptions';

/**
 * This hook is used to create an email template
 */
export const useAdminEmailTemplateCreate = (
  options?: UseMutationOptions<MsApiResponse<EmailTemplateEntity>, Error, EmailTemplateCreatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'email_template:create';

  return useMutation<MsApiResponse<EmailTemplateEntity>, Error, EmailTemplateCreatePayload>(
    (payload: EmailTemplateCreatePayload) => client.admin.emailTemplates.create(payload),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to update an email template
 */
export const useAdminEmailTemplateUpdate = (
  options?: UseMutationOptions<MsApiResponse<EmailTemplateEntity>, Error, EmailTemplateUpdatePayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'email_template:update';

  return useMutation<MsApiResponse<EmailTemplateEntity>, Error, EmailTemplateUpdatePayload>(
    (payload: EmailTemplateUpdatePayload) => client.admin.emailTemplates.update(payload.id, payload.data),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};

/**
 * This hook is used to delete an email template
 */
export const useAdminEmailTemplateDelete = (options?: UseMutationOptions<MsApiResponse<boolean>, Error, string>) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();

  const key = 'email_template:delete';

  return useMutation<MsApiResponse<boolean>, Error, string>(
    (id: string) => client.admin.emailTemplates.delete(id),
    buildOptions(queryClient, [key, 'detail'], options)
  );
};
