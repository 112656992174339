import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

export type SmartyStreetsConfig = {
  embeddedKey: string;
  hostName?: string;
};

export type SmartyStreetsSuggestion = {
  streetLine: string;
  secondary?: string;
  state: string;
  city: string;
  zipcode: string;
};

export type SmartyStreetsLookupResponse = {
  result: SmartyStreetsSuggestion[];
};

export type Address = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

const SmartyStreetsSDK = require('smartystreets-javascript-sdk');
const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;

export const useSmartyStreets = (
  config: SmartyStreetsConfig,
  options?: Omit<UseMutationOptions<SmartyStreetsSuggestion[], Error, string>, 'mutationKey' | 'mutationFn'>
) => {
  const credentials = new SmartyStreetsCore.SharedCredentials(config.embeddedKey, config.hostName);
  const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
  const client = clientBuilder.buildUsAutocompleteProClient();

  return useMutation<SmartyStreetsSuggestion[], Error, string>(
    ['smartyStreets'],
    async (addressString: string) => {
      const lookup = new Lookup(addressString);

      const results: SmartyStreetsSuggestion[] = await client
        .send(lookup)
        .then((res: SmartyStreetsLookupResponse) => res.result);

      return results;
    },
    options
  );
};
