import { useQuery } from '@tanstack/react-query';
import { MsAdminApiEndPoints } from '@wolfejs/ms-api-js/src/api/admin/endPoints';
import type { PagedDataSource } from '@wolfejs/ms-api-js/src/types/api';
import { EmailTemplateEntity } from '@wolfejs/ms-api-js/src/types/emailTemplate';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import type { SearchParams } from '@wolfejs/ui/Table/types';
import { useMsClient } from '../../../contexts';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';

export function useAdminEmailTemplates(
  searchParams?: SearchParams,
  options?: ReactQueryOptions<PagedDataSource<EmailTemplateEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsAdminApiEndPoints.emailTemplates.list(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.emailTemplates.list(searchParams)).data,
  });
}

export function useAdminEmailTemplate(id: string, options?: ReactQueryOptions<EmailTemplateEntity>) {
  const { client } = useMsClient();
  const key = MsAdminApiEndPoints.emailTemplates.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.emailTemplates.get(id)).data,
  });
}
