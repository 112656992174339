import { useQuery } from '@tanstack/react-query';

import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { BuyerBusinessEntity } from '@wolfejs/ms-api-js/src/types/buyers';
import type { BuyerUserMapping } from '@wolfejs/ms-api-js/src/types/mappings';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { useMsClient } from '../..';

/**
 * Get a buyer by id
 */
export function useBuyer(id: string, options?: ReactQueryOptions<BuyerBusinessEntity>) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.buyers.get(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.buyer.get(id)).data,
  });
}

/**
 * Get a buyer by id
 */
export function useBuyerMerchantByBusinessId(businessId: string, options?: ReactQueryOptions<BuyerUserMapping>) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.buyers.getBuyerMerchantByBusinessId(businessId);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.buyer.getBuyerMerchantByBusinessId(businessId)).data,
  });
}
